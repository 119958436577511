import axiosWraper from './axiosWraper'

const proxyUrl = '/node_server'

const nodeServerParttime = {

  // 兼职工作
  /**
   * 获取兼职信息列表数据(大列表，只看已审核通过的内容)
   * @param params
   * {
   *      pageNum: 1, pageSize: 10,
   *      province: 省份, city: 城市,
   *      only_self: 是否只看自己,
   *      is_examine: 是否审核, examine_type: 审核状态(0 不通过，1 通过),
   *      is_active: 是否启用
   * }
   */
  parttimeJobInfo_getPage: async params => {
    const url = `${proxyUrl}/parttimeJobInfo/getPage`
    return axiosWraper.getData(url, params)
  },

  /**
   * 更新启用状态
   * @param params
   * {
   *    "province": "440000",
   *    "city": "440100",
   *    "introduction": "1、广州本地xxx兼职\n2、需要xxx人\n3、xxxx时间进场\n4、联系人xxx",
   *    "name": "联系人",
   *    "phone_number": "电话号码",
   *    "qq_number": "qq号码",
   *    "wechat": "微信号",
   *    "title": "标题",
   *    "keyword": "关键词,关键词2,关键词3"
   * ｝
   */
  parttimeJobInfo_create: async params => {
    const url = `${proxyUrl}/parttimeJobInfo/create`
    return axiosWraper.post(url, params)
  },

  /**
   * 更新启用状态
   * @param params
   * {
   *   id: ID,
   *   is_active: 是否启用
   * }
   */
  parttimeJobInfo_setIsActive: async params => {
    const url = `${proxyUrl}/parttimeJobInfo/setIsActive`
    return axiosWraper.post(url, params)
  },

  // 兼职信息审核
  /**
   * 获取兼职信息审核列表数据
   * @param params
   * {
   *      pageNum: 1, pageSize: 10,
   *      province: 省份, city: 城市,
   *      job_id: 工作id,
   *      is_examine: 是否审核, examine_type: 审核状态(0否，1是),
   *      is_active: 是否启用, audit_type: 审核类型（1新建，2更新，3启用）
   * }
   */
  parttimeJobAuditInfo_getPage: async params => {
    const url = `${proxyUrl}/parttimeJobAuditInfo/getPage`
    return axiosWraper.getData(url, params)
  },

  /**
   * 审核兼职信息
   * @param params   * {
   *    "id": "兼职工作审核表id",
   *    "examine_type": "审核状态0：不通过，1通过",
   *    "examine_message": "审核意见",
   * ｝
   */
  parttimeJobAuditInfo_audit: async params => {
    const url = `${proxyUrl}/parttimeJobAuditInfo/audit`
    return axiosWraper.post(url, params)
  },

}

export default nodeServerParttime
