<template>
  <div class="manage-parttime-info" >
    <div class="table-header">
      <div class="query-item">
        <span class="query-item-label">省份: </span>
        <el-select v-model="queryParams.province" class=" query-item-input m-2" clearable placeholder="选择" @change="selectProvince">
          <template v-for="(item, index) in provinceList" :key="index" >
            <el-option :label="item.region_name" :value="item.region_code"></el-option>
          </template>
        </el-select>
      </div>
      <div class="query-item">
        <span class="query-item-label">城市: </span>
        <el-select  v-model="queryParams.city" class=" query-item-input m-2" :disabled="!citySelect" clearable placeholder="选择">
          <template v-for="(item, index) in cityList" :key="index" >
            <el-option :label="item.region_name" :value="item.region_code"></el-option>
          </template>
        </el-select>
      </div>
      <div class="btn-group">
        <el-button type="primary" @click="searchBtn">查询</el-button>
        <el-button @click="resetBtn">重置</el-button>
      </div>

    </div>
    <div class="table-content custom-overflow">
      <el-table :data="pageData.rows" stripe style="width: 100%" show-overflow-tooltip >
        <el-table-column prop="province_name" label="省份" />
        <el-table-column prop="title" label="标题"  width="200"  />
        <el-table-column prop="introduction" label="内容" width="320" />
        <el-table-column prop="keyword" label="关键词"  width="120"/>
        <el-table-column prop="examine_status_enum" label="审核状态" />
        <el-table-column prop="create_time" label="创建时间" />
        <el-table-column label="是否启用" >
          <template #default="scope">
            <el-switch size="small" v-model="scope.row.is_active_enum" disabled  />
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <div class="total">记录数: {{pageData.total}}条</div>
        <el-pagination background layout="prev, pager, next" :page-size="pageSize" :current-page="currentPage" :total="pageData.total" @current-change="currentChange" />
      </div>
    </div>
  </div>
</template>

<script>

import nodeServer from '../net/nodeServer'
import {dateUtils} from '../utils/dateUtils'
import nodeServerParttime from '@/net/nodeServerParttime'

export default {
  name: 'ManageParttimeInfo',
  components: {

  },
  data () {
    return {
      queryParams: {
        province: '',
        city: '',
      },
      provinceList: [],
      cityList: [],
      provinceIsCitys: ['110000', '120000', '310000', '510000'],
      citySelect: false,

      pageData: {
        rows: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },

    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    init () {
      //  初始化行政区划
      this.initProvince();

      this.searchBtn();
    },
    resetBtn(){
      this.queryParams =  {
        province: '',
        city: '',
      }
      this.citySelect = false;

    },
    async initProvince(){
      let res = await nodeServer.regionInfo_getNextByRegionCodeAndLevel({
        region_code: '100000',
        region_level: 1
      });
      if(res.success && res.content){
        this.provinceList = res.content;
      }
    },
    async selectProvince(){
      // 没有省份，或者是直辖市的，城市不能选择
      if(this.queryParams.province == null
        || this.provinceIsCitys.includes(this.queryParams.province)){
        this.citySelect = false;
      } else {
        // 获取下一级城市列表
        this.cityList = [];
        let res = await nodeServer.regionInfo_getNextByRegionCodeAndLevel({
          region_code: this.queryParams.province,
          region_level: 2
        });
        if(res.success && res.content){
          this.cityList = res.content;
        }
        this.citySelect = true;
      }
    },
    // 查询
    async searchBtn(){
      let searchParams = {
        pageNum: this.pageData.currentPage,
        pageSize: this.pageData.pageSize,
        province: this.queryParams.province,
        city: this.queryParams.city,
      }
      let res = await nodeServerParttime.parttimeJobInfo_getPage(searchParams);
      if(res.success && res.content){
        // 处理数据
        for(let row of res.content.rows ){
          // 审核状态
          let examineTypeMap = {0: '审核不通过', 1: '审核通过'};
          row.examine_type_enum = examineTypeMap[row.examine_type];
          // 是否审核
          let isNoMap = {0: '否', 1: '是'};
          row.is_examine_enum = isNoMap[row.is_examine];
          // 是否启用
          let trueFalseMap = {0: false, 1: true};
          row.is_active_enum = trueFalseMap[row.is_active];
          // examine_status_enum 审核状态展示(0: 审核中, 1: 审核通过, 2: 审核不通过)
          let examineStatusMap = {0: '审核中', 1: '审核通过', 2: '审核不通过'};
          if(row.is_examine == 0){
            row.examine_status = 0;
          }else{
            if(row.examine_type == 0){
              row.examine_status = 2;
            }else{
              row.examine_status = 1;
            }
          }
          row.examine_status_enum = examineStatusMap[row.examine_status];
        }

        this.pageData.rows = res.content.rows;
        this.pageData.total = res.content.total;
      }
    },
    currentChange(currentPage){
      this.pageData.currentPage = currentPage;
      this.searchBtn()
    },
  }
}
</script>
<style lang="scss" scoped>
.manage-parttime-info{
  width: 100%;
  height: 100%;
  .table-header{
    height: 80px;
    line-height: 80px;
    display: flex;
    .query-item{
      margin-left: 64px;
      .query-item-label{
        text-align: right;
      }
      .query-item-input{
        margin-left: 16px;
        width: 120px;
      }
    }
    .btn-group{
      margin-left: 32px;
      .el-button{
        margin-right: 12px;
        margin-bottom: 6px;
      }
    }
  }
  .table-content{
    height: calc(100% - 80px);
    overflow-y: auto;
    .pagination{
      margin-top: 1rem;
      position: relative;
      float: right;
      display: flex;
      .el-pagination{
        margin-right: 32px;
      }
      .total{
        line-height: 40px;
        font-size: 14px;
        margin-right: 24px;
      }
    }
  }
}
</style>
<style lang="scss">
  .order-info-dialog{
    width: 1200px !important;
    .el-dialog__body{
      padding: 0;
    }
  }
</style>
