<template>
  <div class="parttime-job-info" >
    <div class="info-content" v-if="this.showModule == 1">
      <div class="main-navigation">
        <div class="main-search-box">
          <div class="query-item">
            <span class="query-item-label">省份: </span>
            <el-select v-model="queryParams.province" class=" query-item-input m-2" clearable placeholder="选择" @change="selectProvince">
              <template v-for="(item, index) in provinceList" :key="index" >
                <el-option :label="item.region_name" :value="item.region_code"></el-option>
              </template>
            </el-select>
          </div>
          <div class="query-item">
            <span class="query-item-label">城市: </span>
            <el-select  v-model="queryParams.city" class=" query-item-input m-2" :disabled="!citySelect" clearable placeholder="选择">
              <template v-for="(item, index) in cityList" :key="index" >
                <el-option :label="item.region_name" :value="item.region_code"></el-option>
              </template>
            </el-select>
          </div>

          <div class="btn-group">
            <el-button type="primary" @click="searchBtn()">查询</el-button>
            <el-button @click="resetBtn">重置</el-button>
            <el-button @click="releaseBtn">我要发布</el-button>
          </div>
        </div>
      </div>
      <div class="main-show">
        <div class="main-show-left custom-overflow" @scroll="handleScroll">
          <template v-for="(item, index) in pageData.rows" :key="index" >
            <div class="left-item" :class = "{active: this.selectPageIndex == index}" @click="clickPageItem(index)">
              <div class="left-item-title">{{ item.title }}</div>
              <div class="left-item-keyword" v-if="item.keywordList">
                <template v-for="(keyword, index1) in item.keywordList" :key="index1" >
                  <div class="left-item-keyword-item">{{ keyword }}</div>
                </template>
              </div>
              <div class="left-item-foot">
                <div class="left-item-foot-name">
                  <i class="iconfont icon-yonghufill"></i>
                  <span>{{ item.name }}</span>
                </div>
                <div class="left-item-foot-address">
                  <i class="iconfont icon-dingwei"></i>
                  <span>{{ item.province_name }}</span>
                </div>
              </div>
            </div>
          </template>
          <div class="left-item-loading" v-loading="true" v-if="this.pageLoading" ></div>
        </div>
        <div class="main-show-right" v-if="this.selectPageItem != null">
          <div class="right-header">
            <div class="right-header-title ellipse">{{ selectPageItem.title }}</div>
            <div class="right-header-link">
              <div class="right-header-link-item">
                <i class="iconfont icon-dingwei"></i>
                <span>{{ selectPageItem.address_name }}</span>
              </div>
              <div class="right-header-link-item" v-if="selectPageItem.name">
                <i class="iconfont icon-yonghufill"></i>
                <span>{{ selectPageItem.name }}</span>
              </div>
              <div class="right-header-link-item" v-if="selectPageItem.wechat">
                <i class="iconfont icon-weixin"></i>
                <span>{{ selectPageItem.wechat }}</span>
              </div>
              <div class="right-header-link-item" v-if="selectPageItem.phone_number">
                <i class="iconfont icon-dianhua"></i>
                <span>{{ selectPageItem.phone_number }}</span>
              </div>
              <div class="right-header-link-item" v-if="selectPageItem.qq_number">
                <i class="iconfont icon-qq"></i>
                <span>{{ selectPageItem.qq_number }}</span>
              </div>
            </div>
            <div class="right-header-keyword" v-if="selectPageItem.keywordList">
              <template v-for="(keyword, index1) in selectPageItem.keywordList" :key="index1" >
                <div class="right-header-keyword-item">{{ keyword }}</div>
              </template>
            </div>
          </div>
          <div class="right-content-jobinfo">信息描述</div>
          <div class="right-content custom-overflow" v-if="selectPageItem.introductionList">
            <template v-for="(introduction, index) in selectPageItem.introductionList" :key="index" >
              <span class="right-content-span">{{introduction}}</span>
              <br />
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="info-edit" v-if="this.showModule == 2">
      <ParttimeJobInfoEdit></ParttimeJobInfoEdit>
    </div>
  </div>
</template>

<script>

import { dateUtils } from '@/utils/dateUtils'
import nodeServer from '@/net/nodeServer'
import ParttimeJobInfoEdit from '@/views/ParttimeJobInfoEdit.vue'
import nodeServerParttime from '@/net/nodeServerParttime'

export default {
  name: 'ParttimeJobInfo',
  components: {
    ParttimeJobInfoEdit
  },
  data () {
    return {
      showModule: 1,  // 展示模块（1： 列表信息展示， 2： 维护自己发布内容）
      queryParams: {
        province: '',
        city: '',
      },
      provinceList: [],
      cityList: [],
      provinceIsCitys: ['110000', '120000', '310000', '510000'],
      citySelect: false,
      pageData: {
        rows: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
      selectPageIndex: 0,
      selectPageItem: null,
      pageLoading: false
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    init () {
      //  初始化行政区划
      this.initProvince();

      this.searchBtn();
    },
    resetBtn(){
      this.queryParams =  {
        province: '',
        city: '',
      }
      this.citySelect = false;
    },
    async initProvince(){
      let res = await nodeServer.regionInfo_getNextByRegionCodeAndLevel({
        region_code: '100000',
        region_level: 1
      });
      if(res.success && res.content){
        this.provinceList = res.content;
      }
    },
    async selectProvince(){
      // 没有省份，或者是直辖市的，城市不能选择
      if(this.queryParams.province == null
        || this.provinceIsCitys.includes(this.queryParams.province)){
        this.citySelect = false;
      } else {
        // 获取下一级城市列表
        this.cityList = [];
        let res = await nodeServer.regionInfo_getNextByRegionCodeAndLevel({
          region_code: this.queryParams.province,
          region_level: 2
        });
        if(res.success && res.content){
          this.cityList = res.content;
        }
        this.citySelect = true;
      }
    },
    // 查询
    async searchBtn(pageNum = 1, isConcat = false){
      // 不是合并数据的，默认选择第一个
      if(!isConcat){
        this.selectPageIndex = 0;
      }
      this.pageData.pageNum = pageNum;
      let searchParams = {
        pageNum: this.pageData.pageNum,
        pageSize: this.pageData.pageSize,
        province: this.queryParams.province,
        city: this.queryParams.city,
      }
      let res = await nodeServerParttime.parttimeJobInfo_getPage(searchParams);
      if(res.success && res.content){
        // 处理数据
        for(let row of res.content.rows ){
          // 审核状态
          let examineTypeMap = {0: '审核不通过', 1: '审核通过'};
          row.examine_type_enum = examineTypeMap[row.examine_type];
          // 是否审核
          let isNoMap = {0: '否', 1: '是'};
          row.is_examine_enum = isNoMap[row.is_examine];
          // 是否启用
          let trueFalseMap = {0: false, 1: true};
          row.is_active_enum = trueFalseMap[row.is_active];
          // examine_status_enum 审核状态展示(0: 审核中, 1: 审核通过, 2: 审核不通过)
          let examineStatusMap = {0: '审核中', 1: '审核通过', 2: '审核不通过'};
          if(row.is_examine == 0){
            row.examine_status = 0;
          }else{
            if(row.examine_type == 0){
              row.examine_status = 2;
            }else{
              row.examine_status = 1;
            }
          }
          row.examine_status_enum = examineStatusMap[row.examine_status];
          // 关键词处理
          row.keywordList = [];
          let keywordList = [];
          if(row.keyword){
            keywordList = row.keyword.split(',');
          }
          for(let index in keywordList){
            if(index < 6){
              row.keywordList.push(keywordList[index]);
            }
          }

        }
        // 是否是合并的
        if(isConcat){
          this.pageData.rows = this.pageData.rows.concat(res.content.rows);
        } else {
          this.pageData.rows = res.content.rows;
          if(this.pageData.rows && this.pageData.rows.length > 0){
            this.clickPageItem(0);
          }
        }
        this.pageData.total = res.content.total;
      }
    },
    // 点击列表信息
    clickPageItem(index){
      // 展示信息
      this.selectPageItem = this.pageData.rows[index];
      // 处理数据
      // 内容描述
      let introductionList = [];
      if(this.selectPageItem.introduction){
        introductionList = this.selectPageItem.introduction.split('\n');
      }
      this.selectPageItem.introductionList = introductionList;
      // 地址处理
      this.selectPageItem.address_name = this.selectPageItem.province_name;
      if(this.selectPageItem.city_name){
        this.selectPageItem.address_name += ' - ' + this.selectPageItem.city_name;
      }

      // 关键词处理
      this.selectPageItem.keywordList = [];
      let keywordList = [];
      if(this.selectPageItem.keyword){
        keywordList = this.selectPageItem.keyword.split(',');
      }
      for(let index in keywordList){
        if(index < 6){
          this.selectPageItem.keywordList.push(keywordList[index]);
        }
      }
    },
    // 我要发布
    async releaseBtn(){
      // 打开新的页面，可以新增数据，也可以查看自己的列表
      this.showModule = 2;
    },
    async handleScroll(event){
      const dialog = event.target;
      if(dialog.scrollHeight - dialog.scrollTop === dialog.clientHeight){
        if(!this.pageLoading && this.pageData.rows.length < this.pageData.total){
          this.pageLoading = true;
          await this.searchBtn(this.pageData.pageNum + 1, true);
          this.pageLoading = false;
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.parttime-job-info{
  width: 100%;
  height: 100%;
  .info-content, .info-edit{
    width: 100%;
    height: 100%;
  }
  .main-navigation{
    display: flex;
    height: 60px;
    line-height: 60px;
    .main-search-box{
      height: 100%;
      width: 100%;
      display: flex;

      .query-item{
        margin-left: 32px;
        .query-item-label{
          text-align: right;
        }
        .query-item-input{
          margin-left: 12px;
          width: 120px;
        }
      }
      .btn-group{
        margin-left: 64px;
        line-height: 64px;
        .el-button{
          margin-right: 12px;
          margin-bottom: 6px;
        }
      }
    }
  }
  .main-show{
    height: calc(100% - 60px);
    width: 100%;
    display: flex;

    .main-show-left{
      height: 100%;
      width: 440px;
      margin-right: 12px;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 8px;
      .left-item{
        border-radius: 16px;
        width: 100%;
        height: 160px;
        background: #f0f0f0;
        margin-bottom: 12px;
        padding-top: 12px;
        cursor: pointer;
        position: relative;
        &:hover{
          border: 2px solid #e9e9e9;
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);  /* 水平偏移 垂直偏移 模糊半径 颜色 */
        }
        &.active{
          border: 2px solid #e1e1e1;
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);  /* 水平偏移 垂直偏移 模糊半径 颜色 */
        }
        .left-item-title{
          height: 36px;
          line-height: 36px;
          font-size: 18px;
          font-weight: bold;
          margin: 0 24px;
          white-space: nowrap; /* 确保文本不换行 */
          overflow: hidden; /* 隐藏溢出的内容 */
          text-overflow: ellipsis; /* 使用省略号显示溢出的文本 */
        }
        .left-item-keyword{
          display: flex;
          flex-wrap: wrap;
          margin: 6px 24px;
          width: calc(100% - 48px);
          .left-item-keyword-item{
            margin-right: 12px;
            margin-bottom: 8px;
            border-radius: 4px;
            height: 22px;
            line-height: 24px;
            padding: 0 12px;
            background: #e1e1e1;
            font-size: 12px;
          }
        }
        .left-item-foot{
          position: absolute;
          bottom: 0px;
          width: 100%;
          height: 36px;
          background: #e9e9e9;
          display: flex;
          padding: 0 24px;
          line-height: 36px;
          border-radius: 0 0 12px 12px;
          font-size: 15px;
          i.iconfont{
            font-size: 14px;
            color: #d1d1d1;
            margin-right: 6px;
          }
          .left-item-foot-name{

          }
          .left-item-foot-address{
            flex: 1;
            text-align: right;
            margin-right: 12px;
          }
        }
      }
      .left-item-loading{
        width: 100%;
        height: 80px;
      }
    }
    .main-show-right{
      height: calc(100% - 12px);
      width: calc(100% - 480px);
      border-radius: 16px;
      background: #f0f0f0;
      padding: 8px 32px;
      margin: 6px;

      .right-header{
        height: 140px;
        width: 100%;
        border-bottom: 1px solid #d1d1d1;
        .right-header-title{
          height: 60px;
          line-height: 60px;
          font-size: 24px;
          font-weight: bold;
        }
        i.iconfont{
          font-size: 14px;
          color: #d1d1d1;
          margin-right: 6px;
        }
        .right-header-link{
          font-size: 15px;
          line-height: 24px;
          height: 24px;
          margin-bottom: 12px;
          display: flex;
          .right-header-link-item{
            margin-right: 24px;
          }
        }
        .right-header-keyword{
          display: flex;
          flex-wrap: wrap;
          margin: 12px 0;
          width: calc(100% - 48px);
          .right-header-keyword-item{
            margin-right: 16px;
            margin-bottom: 8px;
            border-radius: 4px;
            height: 22px;
            line-height: 24px;
            padding: 0 12px;
            background: #e1e1e1;
            font-size: 12px;
          }
        }

      }
      .right-content-jobinfo{
        height: 32px;
        line-height: 32px;
        font-size: 17px;
        font-weight: bold;
        margin: 12px 0;
      }
      .right-content{
        height: calc(100% - 200px);
        width: calc(100% - 12px);
        font-size: 15px;
        margin-left: 8px;
        overflow-y: auto;
        overflow-x: hidden;
        span{
          line-height: 32px;
        }
      }
    }
  }
}
</style>
<style lang="scss" >
.parttime-job-info{
  .left-item-loading{
    .el-loading-mask{
      background-color: rgba(0,0,0,0);
      .el-loading-spinner{
        top: 20px;
      }
    }
  }
}
</style>
