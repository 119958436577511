<template>
  <div class="manage-module" >
    <el-container>
      <el-aside>
        <el-menu class="el-menu-vertical-demo" :default-active="'0'" @select="handleSelect">
          <template v-for="(item) in menuList">
            <el-menu-item v-if="item.children == null" :index="item.index">
              <i class="iconfont icon-menu"></i>
              <span>{{item.name}}</span>
            </el-menu-item>
            <el-sub-menu v-else :index="item.index">
              <template #title>
                <i class="iconfont icon-menu"></i>
                <span>{{item.name}}</span>
              </template>
              <template v-for="(childrenItem) in item.children">
                <el-menu-item :index="childrenItem.index">
                  <i class="iconfont icon-menu_sub"></i>
                  <span>{{childrenItem.name}}</span>
                </el-menu-item>
              </template>
            </el-sub-menu>
          </template>
        </el-menu>
      </el-aside>
      <el-main>
        <ManageOverviewInfo v-if="selectMenuIndex == '0'"></ManageOverviewInfo>
        <ManageUserInfo v-if="selectMenuIndex == '1'"></ManageUserInfo>
        <ManageApplicationInfo v-if="selectMenuIndex == '2'"></ManageApplicationInfo>
        <ManageOrderInfo v-if="selectMenuIndex == '3'"></ManageOrderInfo>
        <ManageParttimeJobInfo v-if="selectMenuIndex == '4-1'"></ManageParttimeJobInfo>
        <ManageParttimeJobAuditInfo v-if="selectMenuIndex == '4-2'"></ManageParttimeJobAuditInfo>
      </el-main>
    </el-container>
  </div>
</template>

<script>

import ManageOverviewInfo from "./ManageOverviewInfo";
import ManageUserInfo from "./ManageUserInfo";
import ManageApplicationInfo from "./ManageApplicationInfo";
import ManageOrderInfo from "./ManageOrderInfo";
import ManageParttimeJobInfo from "./ManageParttimeJobInfo";
import ManageParttimeJobAuditInfo from "./ManageParttimeJobAuditInfo";

export default {
  name: 'ManageModule',
  components: {
    ManageOverviewInfo,
    ManageApplicationInfo,
    ManageUserInfo,
    ManageOrderInfo,
    ManageParttimeJobInfo,
    ManageParttimeJobAuditInfo,
  },
  data () {
    return {
      menuList: [
        {index: '0', code: 'overviewInfo', name: '总览' },
        {index: '1', code: 'userInfo', name: '用户信息'},
        {index: '2', code: 'applicationInfo', name: '应用信息'},
        {index: '3', code: 'orderInfo', name: '订单信息'},
        {index: '4', code: 'parttimeJob', name: '兼职工作',
          children: [
            {index: '4-1', code: 'parttimeJobInfo', name: '信息列表'},
            {index: '4-2', code: 'parttimeJobAudit', name: '审核列表'},
          ]
        },
      ],
      selectMenuIndex: 0
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    init () {
    },
    handleSelect(key, keyPath){
      this.selectMenuIndex = key;
    }
  }
}
</script>
<style lang="scss" scoped>
.manage-module{
  width: 100%;
  height: 720px;
  font-size: 14px;
  .el-container{
    height: 100%;
    margin: 32px 240px;
    background: #fff;
    .el-aside{
      width: 240px;
      border-right: solid 2px #dcdfe6;
      .el-menu{
        border-right: none;
        .iconfont{
          margin-right: 6px;
        }
        .el-sub-menu{
          li.el-menu-item{
            margin-left: 16px;
          }
        }
      }
    }
  }
}
</style>
