import axiosWraper from './axiosWraper'

const proxyUrl = '/node_server'

const nodeServer = {

  updateCookieToken: () => {
    axiosWraper.updateCookieToken()
  },
  // 用户相关接口
  userInfo_loginBytoken: async params => {
    const url = `${proxyUrl}/userInfo/loginBytoken`
    return axiosWraper.getData(url, params)
  },
  userInfo_loginByWeixinCode: async params => {
    const url = `${proxyUrl}/userInfo/loginByWeixinCode`
    return axiosWraper.post(url, params)
  },
  // pageSize: 10, pageNum: 1, id: xxx, vipType: 是否会员（1：否， 2： 是）, isManager: 是否管理
  userInfo_getPage: async params => {
    const url = `${proxyUrl}/userInfo/getPage`
    return axiosWraper.getData(url, params)
  },
  // 会员商品列表接口
  memberInfo_getPage: async params => {
    const url = `${proxyUrl}/memberInfo/getPage`
    return axiosWraper.getData(url, params)
  },
  // 获取商品微信支付二维码 {code: year}
  weixinPay_createNativeCode: async params => {
    const url = `${proxyUrl}/weixinPay/createNativeCode`
    return axiosWraper.getData(url, params)
  },
  // 订单接口-根据订单号获取
  orderInfo_getOneByOutTradeNo: async params => {
    const url = `${proxyUrl}/orderInfo/getOneByOutTradeNo`
    return axiosWraper.getData(url, params)
  },
  // pageSize: 10, pageNum: 1, userId: xxx
  orderInfo_getPage: async params => {
    const url = `${proxyUrl}/orderInfo/getPage`
    return axiosWraper.getData(url, params)
  },
  // 应用版本接口-获取默认应用
  applicationInfo_getOneByIsDefault: async params => {
    const url = `${proxyUrl}/applicationInfo/getOneByIsDefault`
    return axiosWraper.getData(url, params)
  },
  // pageSize: 10, pageNum: 1
  applicationInfo_getPage: async params => {
    const url = `${proxyUrl}/applicationInfo/getPage`
    return axiosWraper.getData(url, params)
  },
  // 设置默认应用{id: xxx}
  applicationInfo_setDefaultById: async params => {
    const url = `${proxyUrl}/applicationInfo/setDefaultById`
    return axiosWraper.post(url, params)
  },

  /**
   * 创建应用
   * @param params
   * {
   *    name": "审计小哥execl工具",
   *    remarks": "1、支持wps安装",
   *    version": "v1.0.8",
   *    file_group_id": "test"
   * }
   * @returns
   */
  applicationInfo_create: async params => {
    const url = `${proxyUrl}/applicationInfo/create`
    return axiosWraper.post(url, params)
  },
  // 获取统计数据
  dataCount_getOverviewDataCount: async params => {
    const url = `${proxyUrl}/dataCount/getOverviewDataCount`
    return axiosWraper.getData(url, params)
  },

  /**
   * 行政区划接口
   * @param params
   * {
   *    "region_code": "当前级别区划  100000",
   *    "region_level": "下一级区划 1",
   * ｝
   * @returns
   */
  regionInfo_getNextByRegionCodeAndLevel: async params => {
    const url = `${proxyUrl}/regionInfo/getNextByRegionCodeAndLevel`
    return axiosWraper.getData(url, params)
  }

  // 兼职工作接口
}

export default nodeServer
