<template>
  <div class="weixin-login-content" >
  </div>
</template>

<script>
import {ElLoading, ElMessage} from 'element-plus'
import nodeServer from '../net/nodeServer'
import {getCookie, setCookie} from '../net/cookies'

export default {
  name: 'WeixinLoginOffice',
  components: {

  },
  data () {
    return {
      queryCode: '',
      queryState: ''
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    init () {
      const loading = ElLoading.service({
        lock: true,
        text: '正在获取信息',
        background: 'rgba(255, 255, 255, 0.7)',
        customClass: 'login-loading'
      })
      // this.getUserInfo();
      //
      // setTimeout(() => {
      //   loading.close()
      // }, 2000)
    },
    async getUserInfo(){
      // 获取路由参数
      this.quertCode = this.$route.query.code;
      this.quertState = this.$route.query.state;
      console.log(this.quertCode);
      console.log(this.quertState);
      // todo: 获取到用户信息之后，执行逻辑，刷新页面
      let res = await nodeServer.userInfo_loginByWeixinCode({code: this.quertCode});
      console.log(res);
      if(res.success && res.content){
        setCookie("userInfo", JSON.stringify(res.content), 30);
        setCookie("token", res.content.token, 30);
        // 刷新iframe外面
        try{
          window.parent.document.getElementById("weixinLoginSuccessBtn").click();
        }catch (e) {
        }
      }else{
        ElMessage('登录失败')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.weixin-login-content{
  height: 400px;
  width: 400px;
  margin: auto;
  line-height: 400px;
}
</style>

<style lang="scss">
  .login-loading{
  }
</style>
