<template>
  <div class="parttime-job" >
    <div class="content">
      <div class="header-content">
        <template v-for="(item) in tabList">
          <div class="header-item" :class="{active: this.tabSelect == item.index}" @click="handleClickTab(item)">
            <span>{{ item.name }}</span>
          </div>
        </template>
      </div>
      <div class="main-content">
        <ParttimeJobInfo v-if="this.tabSelect == 0" :key="this.tabList[0].key"></ParttimeJobInfo>
        <ParttimePersonInfo v-if="this.tabSelect == 1" :key="this.tabList[1].key" ></ParttimePersonInfo>
      </div>
    </div>
  </div>
</template>

<script>

import ParttimeJobInfo from "./ParttimeJobInfo.vue";
import ParttimePersonInfo from "./ParttimePersonInfo.vue";

export default {
  name: 'ParttimeJob',
  components: {
    ParttimeJobInfo,
    ParttimePersonInfo
  },
  data () {
    return {
      tabList: [
        {index: 0, name: '兼职工作', key: 0},
        {index: 1, name: '兼职人员', key: 1},
      ],
      tabSelect: 0
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    init () {

    },
    handleClickTab(tabItem){
      if(this.tabSelect == tabItem.index){
        tabItem.key = tabItem.index + '_' + new Date().getTime();
      }
      this.tabSelect = tabItem.index;
    }
  }
}
</script>
<style lang="scss" scoped>
.parttime-job{
  width: 100%;
  height: 800px;
  font-size: 14px;
  .content {
    height: 100%;
    margin: 24px 240px;
    .header-content{
      height: 40px;
      display: flex;
      border-bottom: 1px solid #8c8c8c;
      .header-item{
        margin-left: 12px;
        text-align: center;
        font-size: 17px;
        cursor: pointer;
        height: 40px;
        line-height: 32px;
        width: 96px;
        border-radius: 5px;
      }
      .header-item:hover, .header-item.active{
        font-size: 18px;
        font-weight: bold;
      }
    }
    .main-content{
      height: calc(100% - 40px);
      width: 100%;
    }
  }
}
</style>
