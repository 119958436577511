<template>
  <div class="manage-user-info" >
    <div class="table-header">
      <div class="query-item">
        <span class="query-item-label">用户Id: </span>
        <el-input class="query-item-input" v-model="queryParams.userId" />
      </div>
      <div class="btn-group">
        <el-button type="primary" @click="searchBtn">查询</el-button>
        <el-button @click="resetBtn">重置</el-button>
      </div>

    </div>
    <div class="table-content custom-overflow">
      <el-table :data="pageData.rows" stripe style="width: 100%">
        <el-table-column prop="id" label="ID"  width="80"  />
        <el-table-column prop="user_id" label="用户id"  width="80"  />
        <el-table-column prop="order_name" label="订单名"  />
        <el-table-column prop="order_cost" label="金额(元)"  width="80"  />
        <!--<el-table-column prop="out_trade_no" label="订单编号" />-->
        <!--<el-table-column prop="pay_id" label="订单id" />-->
        <el-table-column prop="pay_status_enum" label="订单状态" width="120" />
        <el-table-column prop="create_time" label="创建时间" />
        <el-table-column prop="success_time" label="支付时间" />
      </el-table>
      <div class="pagination">
        <div class="total">记录数: {{pageData.total}}条</div>
        <el-pagination background layout="prev, pager, next" :page-size="pageSize" :current-page="currentPage" :total="pageData.total" @current-change="currentChange" />
      </div>
    </div>
  </div>

</template>

<script>

import nodeServer from '../net/nodeServer'
import {dateUtils} from '../utils/dateUtils'
export default {
  name: 'ManageOrderInfo',
  components: {
  },
  data () {
    return {
      queryParams: {
        userId: '',
      },
      pageData: {
        rows: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    init () {
      this.searchBtn();
    },
    resetBtn(){
      this.queryParams =  {
        id: '',
        vipType: '',
        isManager: '',
      }
    },
    // 查询
    async searchBtn(){
      let res = await nodeServer.orderInfo_getPage({
        pageNum: this.pageData.currentPage,
        pageSize: this.pageData.pageSize,
        userId: this.queryParams.userId,
      });
      if(res.success && res.content){
        // 处理数据
        for(let row of res.content.rows ){
          if(row.success_time){
            row.success_time = dateUtils.getDateTimeString(new Date(row.success_time));
          }
          row.create_time = dateUtils.getDateTimeString(new Date(row.create_time));
          row.update_time = dateUtils.getDateTimeString(new Date(row.update_time));
          row.order_cost = row.order_cost ? row.order_cost * 0.01 : 0;
          // 交易状态，枚举值
          let payStatusMap = {
            'SUCCESS': '支付成功',
            'REFUND': '转入退款',
            'NOTPAY': '未支付',
            'CLOSED': '已关闭',
            'REVOKED': '已撤销',
            'USERPAYING': '用户支付中',
            'PAYERROR': '支付失败',
          }
          row.pay_status_enum = payStatusMap[row.pay_status];
        }

        this.pageData.rows = res.content.rows;
        this.pageData.total = res.content.total;
      }
    },
    currentChange(currentPage){
      this.pageData.currentPage = currentPage;
      this.searchBtn()
    },
  }
}
</script>
<style lang="scss" scoped>
.manage-user-info{
  width: 100%;
  height: 100%;
  .table-header{
    height: 80px;
    line-height: 80px;
    display: flex;
    .query-item{
      margin-left: 64px;
      .query-item-label{
        text-align: right;
      }
      .query-item-input{
        margin-left: 16px;
        width: 120px;
      }
    }
    .btn-group{
      margin-left: 32px;
      .el-button{
        margin-right: 12px;
        margin-bottom: 6px;
      }
    }
  }
  .table-content{
    height: calc(100% - 80px);
    overflow-y: auto;
    .pagination{
      margin-top: 1rem;
      position: relative;
      float: right;
      display: flex;
      .el-pagination{
        margin-right: 32px;
      }
      .total{
        line-height: 40px;
        font-size: 14px;
        margin-right: 24px;
      }
    }
  }
}
</style>
<style lang="scss">
  .order-info-dialog{
    width: 1200px !important;
    .el-dialog__body{
      padding: 0;
    }
  }
</style>
