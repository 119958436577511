<template>
  <div class="parttime-person-info" >
    功能暂未开放
<!--      <div class="main-navigation">
        <div class="main-search-box"> 过滤条件 ， 省、市区， 等过滤</div>
        <div class="main-tool-box">我要兼职</div>
      </div>
      <div class="main-show">
        <div class="main-show-left">左侧兼职人员列表</div>
        <div class="main-show-left">右侧兼职人员详情</div>
      </div>-->
  </div>
</template>

<script>

export default {
  name: 'ParttimePersonInfo',
  components: {
  },
  data () {
    return {

    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    init () {

    },
  }
}
</script>
<style lang="scss" scoped>
.parttime-person-info{
  width: 100%;
  height: 100%;
}
</style>
