<template>
  <div class="home">
    <div class="title">
      <div class="title-inner">
        <div class="header-inner">
          <!--logo-->
          <div class="logo">
            <router-link :to="'/'" title="秋水长天财务咨询有限公司" style="display: flex;">
              <div class="logo-img"> <img src="../assets/logo.jpg" class="header_logo header-logo" /> </div>
              <div class="logo-title">
                深圳秋水长天财税管理咨询有限公司
              </div>
            </router-link>
          </div>
          <!--菜单-->
          <div class="flex-col">
            <ul class="nav">
              <li v-for="(item,index) in menuList" :class="{active: item.routeName == routerName}">
                <router-link :to="{name: `${item.routeName}`, params: item}" v-if="item.isManager ? (userInfo && userInfo.is_manager == 1) : true" > {{item.name}} </router-link>
              </li>
            </ul>
          </div>
          <!--用户信息-->
          <div class="user-info">
            <i class="iconfont icon-yonghufill"></i>
            <span class="no-login" @click="openLoginDialog" v-if="userInfo == null || userInfo.name == null">未登录</span>
            <el-dropdown v-else>
              <span class="user-name">{{userInfo.name}}</span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="logout()">退出</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>

          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <router-view  @childEmit="childEmitFunction" />
    </div>
    <div class="footer">
      <div class="company-box">
        <div class="company-content">
          <div class="conpany-info">
            <div class="info-text"><span>联系我们</span></div>
            <div class="info-text"><span>电话：</span><span>15013669893</span></div>
            <div class="info-text"><span>时间：</span><span>周一至周五 9：00 - 17：30</span></div>
            <div class="info-text"><span>地址：</span><span>深圳市龙岗区横岗街道六约北社区红棉路6210号卓然居3栋A单元A903c</span></div>
            <div class="info-text"><span>经营项目：</span><span>财务咨询；税务服务；企业管理咨询</span></div>
          </div>
          <div class="account-box">
            <div class="account-img-div">
              <img src="../assets/accountImg/gongzhonghao.jpg" class="account-img" />
            </div>
            <div class="account-title">公众号</div>
            <div class="account-name">少年怒</div>
          </div>
          <div class="account-box">
            <div class="account-img-div">
              <img src="../assets/accountImg/weixin_2.png" class="account-img" />
            </div>
            <div class="account-title">微信号</div>
            <div class="account-name">审计小哥2号</div>
          </div>
          <div class="account-box">
            <div class="account-img-div">
              <img src="../assets/accountImg/qq_1.jpg" class="account-img" />
            </div>
            <div class="account-title">QQ群</div>
            <div class="account-name">审计小哥工具群</div>
          </div>
        </div>
      </div>
      <div class="footer-copyright-box">
        <div class="footer-copyright">
          <div class="copyright-year">©2024</div>
          <div class="copyright-company">深圳秋水长天财税管理咨询有限公司</div>
          <div class="copyright-beian">
            <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2023102621号</a>
          </div>
          <div class="copyright-gongan">
            <div class="gongan-url">
              <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030702005715" target="_blank">粤公网安备 44030702005715号</a>
            </div>
            <img src="../assets/备案图标.png" class="gongan-logo" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--登录窗口-->
  <el-dialog
    v-model="openLoginFlag"
    title=""
    class="login-dialog"
  >
    <WeixinLogin></WeixinLogin>
  </el-dialog>
</template>

<script>

import defaultData from "../data/defaultData";
import "../utils/wxLogin";
import WeixinLogin from "./WeixinLogin";
import {getCookie, setCookie} from '../net/cookies'
import nodeServer from '../net/nodeServer'

export default {
  name: 'Home',
  components: {
    WeixinLogin
  },
  data () {
    return {
      menuList: [],
      routerName: "",
      openLoginFlag: false,
      userInfo: {}
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    init () {
      this.menuList = defaultData.menuList;
      // 获取路由参数
      let queryToken = this.$route.query.token;
      this.getUserInfo(queryToken);
    },
    openLoginDialog(){
      // 显示
      this.openLoginFlag = true;
    },

    async getUserInfo(queryToken){
      // 获取用户信息, 如果有的话, 通过token接口获取信息
      let token = getCookie("token");
      if(queryToken){
        token = queryToken;
      }
      if(token){
        //重新请求获取用户信息
        let res = await nodeServer.userInfo_loginBytoken({token: token});
        if(res.success && res.content){
          let userInfo = res.content;
          this.userInfo = userInfo;
          setCookie("userInfo", JSON.stringify(userInfo), 30);
          setCookie("token", userInfo.token, 30);
          // 更新token
          nodeServer.updateCookieToken();
        }else{
          if(queryToken){
            this.getUserInfo();
          } else {
            // 获取登录信息失败，退出旧的登录，并且清理cookie
            this.logout();
          }
        }
      }
    },
    logout(){
      setCookie("userInfo", '{}', 30);
      setCookie("token", '', 30);
      this.userInfo = {};
    },
    // 检查是否登录
    checkUserIsLogin(){
      if(this.userInfo && this.userInfo.id){
        return true;
      }
      return false;
    },
    /**
     * 子组件调用父节点方法
     * @param type 类型 1: 判断是否登录1: 开通会员-登录
     */
    childEmitFunction(type){
      if(type == 1){
        this.openLoginDialog();
      }
    }
  },
  watch: {
    $router: {
      handler: function (router) {
        this.routerName = router.currentRoute.value.name
      },
      immediate: true,
      deep:true
    },
  },
}
</script>
<style lang="scss" scoped>
.home{
  background-color: #e1e1e1;
  text-align: left;
  position: relative;
  height: 100%;
  .title,.title-inner{
    position: relative;
    transition: background-color .3s,opacity .3s;
    width: 100%;
    z-index: 1001;
    height: 70px;
    .title-inner{
      height: 70px;
    }
    .header-inner{
      height: 100%;
      padding-left: 15px;
      padding-right: 15px;
      margin-left: 240px;
      margin-right: auto;
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
    }
    .logo{
      margin-left: 0;
      margin-right: 30px;
      line-height: 1;
      max-height: 100%;
      width: 400px;
      .header_logo{
        height: 32px;
        transition: max-height .5s;
        display: block;
        width: 32px;
        border-radius: 5px;
      }
      .logo-title{
        color: #000 !important;
        font-size: 20px;
        font-family: cursive;
        font-weight: bold;
        margin: auto 16px;
      }
    }
    .flex-col{
      margin-right: auto;
      max-height: 100%;
      box-sizing: border-box;
      flex: 1;
      .nav{
        align-items: center;
        display: inline-block;
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        margin: 0;
        padding: 0;
        justify-content: flex-start;
        position: relative;
        li{
          margin-left: 0 !important;
          display: inline-block;
          list-style: none;
          margin: 0 48px;
          padding: 0;
          transition: background-color .3s;
          position: relative;
          font-size: 16px;
          font-family: Lato, sans-serif;
          font-weight: 700;
          font-style: normal;
          cursor: pointer;
          line-height: 16px;
          i{
            font-weight: bold;
            font-style: normal;
            line-height: 16px;
            font-size: 20px;
            flex-wrap: wrap;
            cursor: pointer;
            color: #8c8c8c;
            margin-top: 2px;
            margin-right: 2px;
          }
          a{
            align-items: center;
            display: inline-flex;
            flex-wrap: wrap;
            padding: 10px 0;
            color: #8c8c8c;
          }
          a:hover{
            color: #434343;
          }
        }
        li.active{
          a{
            color: #000000;
          }
        }
      }
    }
    .user-info{
      margin-right: 240px;
      font-size: 16px;
      .iconfont{
        font-size: 18px;
        margin-right: 8px;
      }
      .no-login{
        cursor: pointer;
      }
      .no-login:hover{
        color: #666;
      }
      .user-name{
        cursor: pointer;
        font-size: 16px;
      }
      .user-name:hover{
        color: #666;
      }
    }
  }
  .content{
    position: relative;
    box-sizing: border-box;
  }
  .footer{
    bottom: 0;
    background-color: #d9d9d9;
    height: 280px;
    width: 100%;
    color: #595959;
    font-size: 14px;
    .company-box{
      display: flex;
      margin: auto;
      width: 100%;
      .company-content{
        display: flex;
        margin: auto;
        margin-top: 20px;
        .conpany-info{
          margin-right: 160px;
          font-size: 16px;
          line-height: 28px;
          padding: 24px 0;
        }
        .account-box {
          margin-right: 40px;
          margin-top: 20px;
          .account-img-div {
            background-color: #fff;
            width: 120px;
            height: 120px;
            .account-img {
              width: 120px;
              height: 120px;
            }
          }
          .account-title{
            width: 100%;
            margin-top: 8px;
            font-size: 12px;
            text-align: center;
          }
          .account-name {
            width: 100%;
            margin-top: 8px;
            font-size: 14px;
            text-align: center;
          }
        }
      }

    }
    .footer-copyright-box{
      margin: auto;
      display: flex;
      width: 100%;
      margin-top: 20px;
      height: 28px;
      .footer-copyright{
        margin: auto;
        display: flex;
        .copyright-year{
          margin: auto 0;
          padding: 2px 0px;
        }
        .copyright-company{
          margin: auto 0;
          padding: 2px 12px;
          border-right: solid 1px #8c8c8c;
        }
        .copyright-beian{
          margin: auto 0;
          padding: 2px 12px;
          border-right: solid 1px #8c8c8c;
        }
        .copyright-gongan{
          margin: auto 0;
          display: flex;
          padding: 2px 12px;
          .gongan-url{
            margin: auto 0;
            padding: 2px 0px;
          }
          .gongan-logo{
            height: 20px;
            width: 20px;
            margin-left: 4px;
            display: block;
          }
        }
      }
    }
  }
}


</style>
<style lang="scss">
  .login-dialog{
    width: 420px !important;
    .el-dialog__body{
      padding: 0;
    }
  }
</style>
