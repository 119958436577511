<template>
  <div class="parttime-job-info-edit" >
    <div class="main-navigation">
      <div class="main-search-box">
        <div class="query-item">
          <span class="query-item-label">审核状态: </span>
          <el-select  v-model="queryParams.examine_status" class=" query-item-input m-2" clearable placeholder="选择">
            <el-option label="待审核" value="0"></el-option>
            <el-option label="审核通过" value="1"></el-option>
            <el-option label="审核不通过" value="2"></el-option>
          </el-select>
        </div>
        <div class="query-item">
          <span class="query-item-label">是否启用: </span>
          <el-select  v-model="queryParams.is_active" class=" query-item-input m-2" clearable placeholder="选择">
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </div>
        <div class="btn-group">
          <el-button type="primary" @click="searchBtn">查询</el-button>
          <el-button @click="resetBtn">重置</el-button>
          <el-button type="primary" @click="createBtn">新增</el-button>
        </div>
      </div>
    </div>
    <div class="main-show">
      <div class="table-content custom-overflow">
        <el-table :data="pageData.rows" stripe style="width: 100%" show-overflow-tooltip row-class-name="custom-table-row" >
          <el-table-column prop="title" label="标题"  width="200"  />
          <el-table-column prop="introduction" label="内容" width="400" />
          <el-table-column prop="keyword" label="关键词"  width="200"/>
          <el-table-column prop="examine_status_enum" label="审核状态" />
          <el-table-column prop="create_time" label="创建时间" />
          <el-table-column label="是否启用" >
            <template #default="scope">
              <el-switch size="small" :key="scope.$index" v-model="scope.row.is_active_enum" :loading="scope.row.is_active_load == true" @click="tableActiveChange(scope.$index, scope.row)" />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <div class="total">记录数: {{pageData.total}}条</div>
        <el-pagination background layout="prev, pager, next" :page-size="pageSize" :current-page="currentPage" :total="pageData.total" @current-change="currentChange" />
      </div>
    </div>
  </div>


  <el-dialog
    v-model="openCreateInfoFlag"
    title="新建兼职信息"
    class="create-info-dialog"
    :close-on-click-modal="false"
  >
    <div class="create-parttime-job-info">
      <el-form :model="createInfo" label-width="auto" :rules="rules" @submit.native.prevent ref="CreateInfoFormRef" >
        <div class="info-group">
          <div class="info-group-title">基础信息</div>
          <div class="info-row">
            <el-form-item class="info-item" label="标题" prop="title">
              <el-input class="info-item-input" v-model="createInfo.title" />
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="兼职内容" prop="introduction">
              <el-input class="info-item-input" v-model="createInfo.introduction" type="textarea" :autosize="{ minRows: 4, maxRows: 10 }"  />
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="关键词" prop="keyword">
              <el-tag
                v-for="tag in createInfo.keyword"
                :key="tag"
                closable
                :disable-transitions="false"
                @close="handleKeyWordClose(tag)"
              >
                {{ tag }}
              </el-tag>
              <el-input
                v-show="inputKeywordVisible"
                ref="InputKeywordRef"
                v-model="inputKeywordValue"
                class="w-20 keyword-input"
                maxlength="6"
                size="small"
                @keyup.enter="handleInputKeywordConfirm"
                @blur="handleInputKeywordConfirm"
              />
              <el-button v-show="!inputKeywordVisible" class="keyword-button-new-tag" size="small" @click="showInputKeyword">
                + 关键词
              </el-button>
              <span class="tip-text">关键词最多支持6个字</span>
            </el-form-item>
          </div>
        </div>
        <div class="info-group">
          <div class="info-group-title">兼职地点</div>
          <div class="info-row">
            <el-form-item class="info-item" label="省份" prop="province">
              <el-select v-model="createInfo.province" class=" info-item-input m-2" clearable placeholder="选择" @change="selectProvince">
                <template v-for="(item, index) in provinceList" :key="index" >
                  <el-option :label="item.region_name" :value="item.region_code"></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item class="info-item" label="城市" prop="city">
              <el-select v-model="createInfo.city" class="info-item-input m-2" :disabled="!citySelect" clearable placeholder="选择">
                <template v-for="(item, index) in cityList" :key="index" >
                  <el-option :label="item.region_name" :value="item.region_code"></el-option>
                </template>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div class="info-group">
          <div class="info-group-title">联系信息</div>
          <div class="info-row">
            <el-form-item class="info-item" label="联系人" prop="name">
              <el-input class="info-item-input" v-model="createInfo.name" />
            </el-form-item>
            <el-form-item class="info-item" label="手机号" prop="phone_number">
              <el-input class="info-item-input" v-model="createInfo.phone_number" />
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="微信号" prop="wechat">
              <el-input class="info-item-input" v-model="createInfo.wechat" />
            </el-form-item>
            <el-form-item class="info-item" label="QQ号" prop="qq_number">
              <el-input class="info-item-input" v-model="createInfo.qq_number" />
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="btn-group">
        <el-button type="primary" @click="saveInfoBtn" >保存</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>

import { dateUtils } from '@/utils/dateUtils'
import nodeServer from '@/net/nodeServer'
import nodeServerParttime from '@/net/nodeServerParttime'
import { nextTick } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'

export default {
  name: 'ParttimeJobInfoEdit',
  components: {
  },
  data () {
    return {
      queryParams: {
        examine_status: '', //审核状态(0: 审核中, 1: 审核通过, 2: 审核不通过)
        is_active: '',   // 是否启用(0: 否, 1: 是)
      },
      pageData: {
        rows: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
      provinceList: [],
      cityList: [],
      provinceIsCitys: ['110000', '120000', '310000', '510000'],
      citySelect: false,
      // 表单信息
      openCreateInfoFlag: false,
      createInfo: {
        province: "440000",
        city: "440100",
        introduction: "1、广州本地xxx兼职\n2、需要xxx人\n3、xxxx时间进场\n4、联系人xxx",
        name: "联系人",
        phone_number: "电话号码",
        qq_number: "qq号码",
        wechat: "微信号",
        title: "标题",
        keyword: []
      },
      inputKeywordVisible: false,
      inputKeywordValue: '',
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
          { max: 30, message: '最大长度30', trigger: 'blur' },
        ],
        introduction: [
          { required: true, message: '请输入兼职内容', trigger: 'blur' },
          { max: 1000, message: '最大长度1000', trigger: 'blur' },
        ],
        province: [
          { required: true, message: '请选择省份', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
          { max: 10, message: '最大长度10', trigger: 'blur' },
        ],
        wechat: [
          { max: 10, message: '最大长度10', trigger: 'blur' },
        ],
        phone_number: [
          { pattern : /^1[3-9]\d{9}$|^0\d{2,3}-\d{7,8}$/, message: '请输入正确的手机号', trigger: ['blur'] }
        ],
        qq_number: [
          { pattern : /^\d{5,11}$/, message: '请输入正确的QQ号码', trigger: ['blur'] }
        ]
      },
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    init () {
      this.searchBtn();
    },
    resetBtn(){
      this.queryParams =  {
        province: '',
        city: '',
        examine_status: ''
      }
    },
    createBtn(){
      // 新增信息

      //重置表单
      this.createInfo = {
        province: "",
        city: "",
        introduction: "",
        name: "",
        phone_number: "",
        qq_number: "",
        wechat: "",
        title: "",
        keyword: []
      }
      this.inputKeywordVisible = false;
      this.inputKeywordValue = '';
      //  初始化行政区划
      this.initProvince();
      // 打开弹窗
      this.openCreateInfoFlag = true;

    },
    async initProvince(){
      let res = await nodeServer.regionInfo_getNextByRegionCodeAndLevel({
        region_code: '100000',
        region_level: 1
      });
      if(res.success && res.content){
        this.provinceList = res.content;
      }
    },
    async selectProvince(){
      // 没有省份，或者是直辖市的，城市不能选择
      if(this.createInfo.province == null
        || this.provinceIsCitys.includes(this.createInfo.province)){
        this.citySelect = false;
      } else {
        // 获取下一级城市列表
        this.cityList = [];
        let res = await nodeServer.regionInfo_getNextByRegionCodeAndLevel({
          region_code: this.createInfo.province,
          region_level: 2
        });
        if(res.success && res.content){
          this.cityList = res.content;
        }
        this.citySelect = true;
      }
    },
    // 查询
    async searchBtn(){
      // examine_status: '', //审核状态展示(0: 审核中, 1: 审核通过, 2: 审核不通过)
      //   is_active: '',   // 是否启用(0: 否, 1: 是)
      // is_examine: 是否审核, examine_type: 审核状态(0 不通过，1 通过),
      let searchParams = {
        pageNum: this.pageData.currentPage,
        pageSize: this.pageData.pageSize,
        only_self: 1,
        is_active: this.queryParams.is_active,
        is_examine: '',
        examine_type: '',
      }
      if(this.queryParams.examine_status != null && this.queryParams.examine_status != ''){
        searchParams.is_examine = this.queryParams.examine_status == 0 ? 0 : 1;
      }
      if(this.queryParams.examine_status == 1){
        searchParams.examine_type = 1;
      }else if(this.queryParams.examine_status == 2){
        searchParams.examine_type = 0;
      }

      let res = await nodeServerParttime.parttimeJobInfo_getPage(searchParams);
      if(res.success && res.content){
        // 处理数据
        for(let row of res.content.rows ){
          // 审核状态
          let examineTypeMap = {0: '审核不通过', 1: '审核通过'};
          row.examine_type_enum = examineTypeMap[row.examine_type];
          // 是否审核
          let isNoMap = {0: '否', 1: '是'};
          row.is_examine_enum = isNoMap[row.is_examine];
          // 是否启用
          let trueFalseMap = {0: false, 1: true};
          row.is_active_enum = trueFalseMap[row.is_active];
          // examine_status_enum 审核状态展示(0: 审核中, 1: 审核通过, 2: 审核不通过)
          let examineStatusMap = {0: '审核中', 1: '审核通过', 2: '审核不通过'};
          if(row.is_examine == 0){
            row.examine_status = 0;
          }else{
            if(row.examine_type == 0){
              row.examine_status = 2;
            }else{
              row.examine_status = 1;
            }
          }
          row.examine_status_enum = examineStatusMap[row.examine_status];
        }

        this.pageData.rows = res.content.rows;
        this.pageData.total = res.content.total;
      }
    },
    handleKeyWordClose(tag){
      this.createInfo.keyword.splice(this.createInfo.keyword.indexOf(tag), 1)
    },
    handleInputKeywordConfirm(){
      if (this.inputKeywordValue) {
        this.createInfo.keyword.push(this.inputKeywordValue);
      }
      this.inputKeywordVisible = false;
      this.inputKeywordValue = '';
    },
    showInputKeyword(){
      this.inputKeywordVisible = true;
      // 通过this.$refs访问子组件实例
      const myComponentInstance = this.$refs.InputKeywordRef;
      nextTick(() => {
        if(myComponentInstance && myComponentInstance.input){
          myComponentInstance.input.focus();
        }
      })
    },
    async saveInfoBtn(){
      // 保存数据到服务器
      const myComponentInstance = this.$refs.CreateInfoFormRef;
      if (!myComponentInstance) return
      await myComponentInstance.validate((valid, fields) => {
        if (valid) {
          this.createParttimeJob();
        } else {
          // console.log('error submit!', fields)
          // ElMessage.error('请按要求填写内容');
        }
      })
    },
    async createParttimeJob(){
      let entityInfo = JSON.parse(JSON.stringify(this.createInfo));
      entityInfo.keyword = entityInfo.keyword.join(',');
      //  调用接口插入
      let res = await nodeServerParttime.parttimeJobInfo_create(entityInfo);
      if(res.success){
        // 插入成功， 关闭弹窗，刷新列表
        ElMessage.success('创建成功');
        // 关闭弹窗
        this.openCreateInfoFlag = false;
        this.searchBtn();
      }
    },

    async setActiveToService(row){
      // 是否启用
      row.is_active_load = true;
      // 直接调用接口
      let params = {id: row.id, is_active: row.is_active == 1 ? 0 : 1};
      let res = await nodeServerParttime.parttimeJobInfo_setIsActive(params);
      if(res.success){
        // 插入成功， 关闭弹窗，刷新列表
        if(params.is_active == 1){
          ElMessage.success('启用成功');
        } else {
          ElMessage.success('禁用成功');
        }
        this.searchBtn();
      } else {
        ElMessage.error('系统出错');
        this.searchBtn();
      }
    },
    tableActiveChange(index, row){
      this.setActiveToService(row);
      return true;
    },
    currentChange(currentPage){
      this.pageData.currentPage = currentPage;
      this.searchBtn()
    },
  }
}
</script>
<style lang="scss" scoped>
.parttime-job-info-edit{
  width: 100%;
  height: 100%;
  .main-navigation{
    display: flex;
    height: 60px;
    line-height: 60px;
    .main-search-box{
      height: 100%;
      width: 100%;
      display: flex;

      .query-item{
        margin-left: 32px;
        .query-item-label{
          text-align: right;
        }
        .query-item-input{
          margin-left: 12px;
          width: 120px;
        }
      }
      .btn-group{
        margin-left: 64px;
        line-height: 64px;
        .el-button{
          margin-right: 12px;
          margin-bottom: 6px;
        }
      }
    }
  }
  .main-show{
    height: calc(100% - 60px);
    width: 100%;
    background: #fff;
    .table-content{
      height: calc(100% - 80px);
      overflow-y: auto;
    }
    .pagination{
      margin-top: 1rem;
      position: relative;
      float: right;
      display: flex;
      .el-pagination{
        margin-right: 32px;
      }
      .total{
        line-height: 40px;
        font-size: 14px;
        margin-right: 24px;
      }
    }
  }
}

//弹窗
.create-parttime-job-info{
  padding: 0 20px 24px 20px;
  .info-group{
    .info-group-title{
      height: 24px;
      line-height: 22px;
      font-size: 14px;
      border-bottom: 1px solid #8c8c8c;
      margin-bottom: 16px;
    }
    .info-row{
      display: flex;
      .info-item{
        flex: 1;
        .keyword-input, .keyword-button-new-tag{
          width: 90px !important;
        }
        .el-tag{
          margin-right: 8px;
        }
        .tip-text{
          font-size: 12px;
          margin-left: 12px;
          color: #8c8c8c;
        }
      }
    }
  }
  .btn-group{
    text-align: center;
  }
}
</style>

<style lang="scss">
.create-info-dialog{
  width: 800px !important;
  .el-dialog__body{
    padding: 0;
  }
}
.parttime-job-info-edit{
  .table-content{
    .custom-table-row{
      height: 54px;
    }
  }
}
</style>
