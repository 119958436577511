<template>
  <div class="main-view">
    <div class="content">
      <div class="text-content">
        <div class="text-title">审计助手Office插件</div>
        <div class="text-info">提高审计效率，减少熬夜加班</div>

        <div class="btn-group">
          <div class="download-box">
            <el-button @click="clickDownload">
              <el-icon><Files /></el-icon>工具下载
            </el-button>
          </div>
          <div class="menber-box">
            <el-button @click="clickMember">
              <el-icon><Present /></el-icon>开通会员
            </el-button>
          </div>
        </div>
        <div class="version-info">
          <div class="version-num" :title="applicationInfo ? applicationInfo.remarks : ''">
            当前版本号： <span>{{applicationInfo ? applicationInfo.version : ''}}</span>
          </div>
          <div class="version-time">
            <span>{{applicationInfo ? applicationInfo.updateTimeStr : ''}}</span>
          </div>
        </div>
      </div>
      <div class="execl-addin-img">
        <img src="../assets/home-image.png" class="header_logo header-logo" />
      </div>

    </div>
    <div class="introduce-list">
      <div class="dependent-content">
        <span>审计助手Office插件使用 VSTO 开发，可以安装于Windows平台（Windows 10、11），支持WPS、Office 2013、Office 2016、Office 2019、Office 2021、Excel 365。<br/></span>
        <span>工具依赖于.Net4.7.2运行环境，如果安装插件时报错可以通过如下链接下载运行环境进行安装。<br/></span>
        <div class="dependent-btn">
          <span >.NET环境下载链接</span>
          <span style="font-weight: bold;">(安装报错时需要进行下载安装)：</span>
          <a href="https://pan.baidu.com/s/1PqLDz7Ae8DKlHJw4s-5iPg?pwd=sjxg" target="_blank">百度网盘</a>
          <a href="https://dotnet.microsoft.com/zh-cn/download/dotnet-framework/net472" target="_blank">微软官网</a>
        </div>
        <div class="dependent-btn">
          <span>VSTO环境下载链接</span>
          <span style="font-weight: bold;">(安装后没有工具的，需要先下载VSTO环境安装，再进行重新安装工具)：</span>
          <a href="https://pan.baidu.com/s/11bYK5BQfGH7eH9oNGkOBmg?pwd=sjxg" target="_blank">百度网盘</a>
          <a href="https://www.microsoft.com/zh-cn/download/details.aspx?id=105522" target="_blank">微软官网</a>
        </div>
        <div class="video-btn">
          <span >视频教程：</span>
          <a href="https://space.bilibili.com/414273341" target="_blank">审计小哥B站视频</a>
        </div>
      </div>
    </div>
    <div class="function-introduce">
      <div class="introduce-title">审计小哥工具的优点</div>
      <div class="introduce-tab">
        <div class="introduce-tab-item word" :class="{active: functionSelect == 'Word'}" @click="clickFunctionType('Word')">Word工具</div>
        <div class="introduce-tab-item execl" :class="{active: functionSelect == 'Execl'}" @click="clickFunctionType('Execl')">Execl工具</div>
      </div>
      <div class="introduce-content">
        <div class="left-content custom-overflow">
          <div v-for="(item,index) in functionList" :key="item.code" :class="{active: index == selectFunctionCode}" class="function-item" @click="clickFunctionItem(item)" >
            <div class="item-title">{{item.title}}</div>
            <div class="item-content">{{item.message}}</div>
          </div>
        </div>
        <div class="right-content">
          <el-carousel  direction="vertical" :autoplay="false" trigger="click"  ref="imageCarousel" @change="imageCarouselChange">
            <el-carousel-item v-for="(item,index) in functionList" :key="item.code">
              <el-image :src="item.image" fit="fill" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>

  </div>


  <!--会员窗口-->
  <el-dialog
    v-model="openMemberFlag"
    title="开通会员"
    class="member-dialog"
    :close-on-click-modal = "false"
  >
    <Member></Member>
  </el-dialog>
</template>

<script>
import Member from "./Member";
import { ElMessage } from 'element-plus'
import {getCookie} from "../net/cookies";
import nodeServer from '../net/nodeServer'
import {dateUtils} from '../utils/dateUtils'

export default {
  name: 'Main',
  components: {
    Member

  },
  data () {
    return {
      openMemberFlag: false,
      file_root: 'https://www.qiushuict.com/share_files',
      applicationInfo: {},
      functionSelect: 'Execl',
      functionList: [

      ],
      functionListMap: {
        'Execl': [
          {code: 0, title: '表格合并-工作簿合并', message: '选择文件夹下的工作簿进行合并到一个工作簿中', image: require('../assets/addinImg/execl_1.gif')},
          {code: 1, title: '表格合并-工作表合并', message: '将当前工作簿中的所有工作表合并到一个工作表中', image: require('../assets/addinImg/execl_2.gif')},
          {code: 2, title: '科目匹配-勾稽对方科目', message: '根据科目、日期、凭证号、凭证类型信息，自动匹配生成新的工作表，把累计折旧贷方相关的凭证都筛选出来', image: require('../assets/addinImg/execl_3.gif')},
          {code: 3, title: '科目匹配-生成对方科目', message: '根据科目、日期、凭证号、凭证类型信息，自动匹配生成对方科目列数据', image: require('../assets/addinImg/execl_4.gif')},
          {code: 4, title: '科目匹配-生成现金流', message: '根据科目、日期、凭证号、凭证类型信息，自动匹配生成现金流数据：生成余额列，生成现金流透视表。', image: require('../assets/addinImg/execl_9.gif')},
          {code: 5, title: '抽凭-一键抽凭', message: '根据科目，自动抽凭借方或贷方金额>=某值或者排名靠前的数据', image: require('../assets/addinImg/execl_5.gif')},
          {code: 6, title: '辅助功能-工作表目录', message: '在左边窗口展示当前工作簿中的所有工作表', image: require('../assets/addinImg/execl_6.gif')},
          {code: 7, title: '辅助功能-ROUND函数', message: '可以添加ROUND函数或者四舍五入对数据进行处理，也可以快速去掉ROUND函数', image: require('../assets/addinImg/execl_7.gif')},
          {code: 8, title: '辅助功能-凑数功能', message: '从一堆数据中，找到能相加等于结果的数据集合(目前最大只能支持20个数据的凑数)', image: require('../assets/addinImg/execl_8.gif')},
        ],
        'Word': [
          {code: 0, title: '基础功能-表格样式刷', message: '使用工具可以快速格式化从execl表复制到word中的表格样式', image: require('../assets/addinImg/word_1.gif')},
          {code: 1, title: '基础功能-表格样式配置', message: '可以配置表格字体，表格中边框为虚线、实线或加粗实线等', image: require('../assets/addinImg/word_addin_setconfig.png')},
          {code: 2, title: '基础功能-千位符转换', message: '选中需要千位符转换的数据，点击功能按钮，将会转换为千位符格式', image: require('../assets/addinImg/word_2.gif')},
          {code: 3, title: 'Execl联动-链接Execl', message: '复制execl多个单元格之后，批量链接到word文档中的表格中', image: require('../assets/addinImg/word_3.gif')},
          {code: 4, title: 'Execl联动-链接管理', message: '管理word文档中的域链接，根据工作簿/工作表快速对域链接进行更新、断开链接、修改数据源、调整表格行等操作', image: require('../assets/addinImg/word_4.gif')},
          {code: 5, title: 'Execl联动-生成询证函', message: '根据Execl链接生成询证函，Execl中每一行数据对应一个询证函信息，自动往下遍历生成询证函word文档', image: require('../assets/addinImg/word_5.gif')}
        ]
      },
      selectFunctionCode: 0,
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    async init () {
      // 初始化信息
      this.functionList = this.functionListMap[this.functionSelect];

      // 获取应用信息
      let applicationResult = await nodeServer.applicationInfo_getOneByIsDefault();
      if(applicationResult && applicationResult.success && applicationResult.content){
        this.applicationInfo = applicationResult.content;
        // 更新时间 update_time
        if(this.applicationInfo.update_time){
          try{
            this.applicationInfo.updateTimeStr =  dateUtils.getDateString(new Date(this.applicationInfo.update_time));
          }catch (e) {

          }
        }else{
          this.applicationInfo.updateTimeStr = '';
        }
      }
    },
    clickMember(){
      // ElMessage('功能暂未开放.')
      // 判断用户是否登录
      let token = getCookie("token");
      if(!token){
        // 没有登录， 打开登录页面
        this.$emit('childEmit', 1);
        ElMessage('请先登录.')
        return;
      }else{
        this.openMemberFlag = true;
      }
    },
    clickDownload(){
      let baseUrl = this.file_root;
      if(this.applicationInfo.filesInfoList && this.applicationInfo.filesInfoList.length > 0){
        let filesInfo = this.applicationInfo.filesInfoList[0];
        let filePath = filesInfo.file_path;
        if(filePath){
          window.open(
            baseUrl + filePath,
            '_self'
          );
        }
      } else {
        ElMessage.error('下载错误');
      }
      /*window.open(
        baseUrl + '/qiushuict/addin/v1.0.2/QiuShuiCTAddInSetup.msi',
        '_self'
      );*/
    },
    clickFunctionType(functionSelect){
      this.functionSelect = functionSelect;
      this.functionList = this.functionListMap[this.functionSelect];
    },
    clickFunctionItem(functionItem){
      this.selectFunctionCode = functionItem.code;
      // 切换gif图片
      this.$refs.imageCarousel.setActiveItem(functionItem.code);
    },
    imageCarouselChange(itemCode){
      let selectItem = this.functionList[itemCode];
      this.clickFunctionItem(selectItem)
    }
  }
}
</script>
<style lang="scss" scoped>
.main-view{
  .content{
    /*加载背景图 */
    background-image: url("../assets/home-backgroud.jpg");
    /* 背景图垂直、水平均居中 */
    background-position: center center;
    /* 背景图不平铺 */
    background-repeat: no-repeat;
    /* 当内容高度大于图片高度时，背景图像的位置相对于viewport固定 */
    /*background-attachment: fixed;*/
    /* 让背景图基于容器大小伸缩 */
    background-size: cover;
    border-radius: 20px;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);  /* 水平偏移 垂直偏移 模糊半径 颜色 */

    width: 1400px;
    height: 600px;
    margin: 16px auto;
    position: relative;
    .text-content{
      position: absolute;
      left: 200px;
      top: 160px;
      .text-title{
        font-size: 40px;
        font-family: emoji;
        font-weight: bold;
        margin: 32px 0;
        color: rgba(255,255,255,0.8);
      }
      .text-info{
        font-size: 20px;
        margin: 16px 0;
        color: rgba(255,255,255,0.8);
        font-weight: bold;
      }
      .btn-group{
        margin: 32px 0;
        display: flex;
        .el-button{
          margin-right: 32px;
          height: 42px;
          width: 160px;
          background-color: rgba(255,255,255,0.8);
          font-weight: bold;
          .el-icon{
            margin-right: 8px;
            font-size: 18px;
          }
        }

        .el-button:hover{
          background-color: rgba(255,255,255, 1);
        }
      }

      .version-info{
        color: rgba(255,255,255,0.8);
        font-size: 14px;
        margin-right: 2rem;
        margin-top: 24px;
        cursor: pointer;
        display: flex;
        .version-num{
          margin-right: 16px;
          span{
            font-weight: 600;
          }
        }
        .version-time{
          font-size: 14px;
          border-left: solid 1px rgba(255,255,255,0.8) ;
          padding-left: 16px;
        }
      }
    }
    .execl-addin-img{
      position: absolute;
      right: 180px;
      bottom: 140px;
      width: 440px;
      height: 320px;
      img{
        width: 440px;
        height: 320px;
      }
    }
  }
  .introduce-list{
    padding: 0 300px;
    font-size: 16px;
    line-height: 48px;
    margin: 32px 0;
    height: 280px;
    background: #e1e1e1;
    .dependent-content{
      a{
        margin: 0 16px;
      }
      .video-btn{
        margin: 12px 0;
        font-size: 24px;
        span{
          color: red;
          font-weight: bold;
          margin-right: 20px;
        }
      }
    }
  }
  .function-introduce{
    padding: 12px 300px;
    font-size: 16px;
    line-height: 32px;
    background: #fff;
    margin-bottom: 32px;
    .introduce-title{
      text-align: center;
      color: #23282d;
      font-size: 24px;
      margin: 32px;
    }
    .introduce-tab{
      display: flex;
      width: 100%;
      height: 56px;
      margin: 24px 0;
      font-size: 20px;
      font-weight: bold;
      .introduce-tab-item{
        width: 200px;
        height: 56px;
        margin: 0 24px;
        border-radius: 4px;
        line-height: 56px;
        text-align: center;
        cursor: pointer;
        opacity: 0.3;
        &:hover{
          opacity: 1;
        }
        &.active{
          opacity: 1;
        }
        &.word{
          background: #2b7dd3;
        }
        &.execl{
          background: #22a466;
        }
      }
    }
    .introduce-content{
      display: flex;
      height: 480px;

      .left-content{
        width : 460px;
        margin-right: 20px;
        overflow-y: auto;
        cursor: pointer;
        .function-item{
          margin: 12px 16px;
          height: 104px;
          color: #595959;
          padding: 12px 20px;
          border-radius: 6px;
          &:hover{
            color: #262626;
            background: #f0f0f0;
          }
          &.active{
            color: #262626;
            background: #f0f0f0;
          }
          .item-title{
            font-size: 16px;
            font-weight: bold;
          }
          .item-content{
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
      .right-content{
        width : 840px;
        height: 480px;
        .el-carousel{
          height: 480px;
          .el-carousel__item{
            height: 480px;
          }
          .el-image{
            height: 480px;
            img{
              height: 480px;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
  .member-dialog{
    width: 600px !important;
    background: #f5f5f5;
    .el-dialog__body{
      padding: 12px 26px;
      border: #d9d9d9 solid 1px;
    }
  }
</style>
