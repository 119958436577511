import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/rem';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/styles/index.scss'
import './assets/styles/reset.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// 引入iconfont
import './assets/iconfont/iconfont.css'

const app = createApp(App)

app.use(store)
app.use(router)
app.use(ElementPlus)

// icon样式
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.mount('#app')

window.app = app
window.agLayer = null
