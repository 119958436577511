<template>
  <div class="manage-parttime-audit-info" >
    <div class="table-header">
      <div class="query-item">
        <span class="query-item-label">省份: </span>
        <el-select v-model="queryParams.province" class=" query-item-input m-2" clearable placeholder="选择" @change="selectProvince">
          <template v-for="(item, index) in provinceList" :key="index" >
            <el-option :label="item.region_name" :value="item.region_code"></el-option>
          </template>
        </el-select>
      </div>
      <div class="query-item">
        <span class="query-item-label">城市: </span>
        <el-select  v-model="queryParams.city" class=" query-item-input m-2" :disabled="!citySelect" clearable placeholder="选择">
          <template v-for="(item, index) in cityList" :key="index" >
            <el-option :label="item.region_name" :value="item.region_code"></el-option>
          </template>
        </el-select>
      </div>
      <div class="query-item">
        <span class="query-item-label">是否审核: </span>
        <el-select  v-model="queryParams.is_examine" class=" query-item-input m-2" clearable  placeholder="选择">
          <el-option label="是" :value="1" />
          <el-option label="否" :value="0" />
        </el-select>
      </div>
      <div class="btn-group">
        <el-button type="primary" @click="searchBtn">查询</el-button>
        <el-button @click="resetBtn">重置</el-button>
      </div>

    </div>
    <div class="table-content custom-overflow">
      <el-table :data="pageData.rows" stripe style="width: 100%" show-overflow-tooltip>
        <el-table-column prop="title" label="标题"  width="120"  />
        <el-table-column prop="introduction" label="内容" width="200" />
        <el-table-column prop="audit_type_enum" label="审核类型" />
        <el-table-column prop="examine_status_enum" label="审核状态" />
        <el-table-column prop="examine_name" label="审核人" />
        <el-table-column prop="examine_message" label="审核信息" />
        <el-table-column prop="create_time" label="申请时间" />
        <el-table-column prop="examine_time" label="审核时间" />
        <el-table-column label="操作">
          <template #default="scope">
            <el-button size="small" type="primary" @click="openAuditInfoDialog(scope.$index, scope.row)" :disabled="scope.row.is_examine==1">审核</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <div class="total">记录数: {{pageData.total}}条</div>
        <el-pagination background layout="prev, pager, next" :page-size="pageSize" :current-page="currentPage" :total="pageData.total" @current-change="currentChange" />
      </div>
    </div>
  </div>


  <el-dialog
    v-model="openAuditInfoFlag"
    title="兼职信息审核"
    class="audit-info-dialog"
    :close-on-click-modal="false"
  >
    <div class="audit-parttime-job-info">
      <el-form :model="auditInfo" label-width="auto" :rules="rules" @submit.native.prevent ref="AuditInfoFormRef" >
        <div class="info-group">
          <div class="info-group-title">基础信息</div>
          <div class="info-row">
            <el-form-item class="info-item" label="标题" prop="title">
              <el-input class="info-item-input" v-model="auditInfo.title" disabled />
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="兼职内容" prop="introduction">
              <el-input class="info-item-input" v-model="auditInfo.introduction" type="textarea" :autosize="{ minRows: 4, maxRows: 10 }" disabled  />
            </el-form-item>
          </div>
          <div class="info-row" v-if="auditInfo && auditInfo.keywordList">
            <el-form-item class="info-item" label="关键词" prop="keyword">
              <el-tag
                v-for="tag in auditInfo.keywordList"
                :key="tag"
                closable
                :disable-transitions="false"
                @close="handleKeyWordClose(tag)"
              >
                {{ tag }}
              </el-tag>
            </el-form-item>
          </div>
        </div>
        <div class="info-group">
          <div class="info-group-title">兼职地点</div>
          <div class="info-row">
            <el-form-item class="info-item" label="省份" prop="province">
              <el-input class="info-item-input" v-model="auditInfo.province_name" disabled />
            </el-form-item>
            <el-form-item class="info-item" label="城市" prop="city">
              <el-input class="info-item-input" v-model="auditInfo.city_name" disabled />
            </el-form-item>
          </div>
        </div>
        <div class="info-group">
          <div class="info-group-title">联系信息</div>
          <div class="info-row">
            <el-form-item class="info-item" label="联系人" prop="name">
              <el-input class="info-item-input" v-model="auditInfo.name" disabled />
            </el-form-item>
            <el-form-item class="info-item" label="手机号" prop="phone_number">
              <el-input class="info-item-input" v-model="auditInfo.phone_number" disabled />
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="微信号" prop="wechat">
              <el-input class="info-item-input" v-model="auditInfo.wechat" disabled />
            </el-form-item>
            <el-form-item class="info-item" label="QQ号" prop="qq_number">
              <el-input class="info-item-input" v-model="auditInfo.qq_number" disabled />
            </el-form-item>
          </div>
        </div>
        <div class="info-group">
          <div class="info-group-title">审核信息</div>
          <div class="info-row">
            <el-form-item class="info-item" label="审核状态" prop="examine_type">
              <el-select v-model="auditInfo.examine_type" class=" info-item-input m-2" clearable placeholder="选择">
                <el-option label="通过" :value="1"></el-option>
                <el-option label="不通过" :value="0"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="审核意见" prop="examine_message">
              <el-input class="info-item-input" v-model="auditInfo.examine_message" type="textarea" :autosize="{ minRows: 2, maxRows: 4 }"  />
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="btn-group">
        <el-button type="primary" @click="auditInfoBtn" >审核</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>

import nodeServer from '../net/nodeServer'
import {dateUtils} from '../utils/dateUtils'
import nodeServerParttime from '@/net/nodeServerParttime'
import { ElMessage } from 'element-plus'

export default {
  name: 'ManageParttimeAuditInfo',
  components: {

  },
  data () {
    return {
      queryParams: {
        province: '',
        city: '',
        is_examine: '',
      },
      provinceList: [],
      cityList: [],
      provinceIsCitys: ['110000', '120000', '310000', '510000'],
      citySelect: false,

      pageData: {
        rows: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },

      // 审核
      // 表单信息
      openAuditInfoFlag: false,
      auditInfo: {
      },
      rules: {
        examine_type: [
          { required: true, message: '请选择审核状态', trigger: 'change' }
        ],
        examine_message: [
          { required: true, message: '请填写审核意见', trigger: 'blur' },
          { max: 100, message: '最大长度100', trigger: 'blur' },
        ],
      },
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    init () {
      //  初始化行政区划
      this.initProvince();

      this.searchBtn();
    },
    resetBtn(){
      this.queryParams =  {
        province: '',
        city: '',
        is_examine: '',
      }
      this.citySelect = false;
    },
    async initProvince(){
      let res = await nodeServer.regionInfo_getNextByRegionCodeAndLevel({
        region_code: '100000',
        region_level: 1
      });
      if(res.success && res.content){
        this.provinceList = res.content;
      }
    },
    async selectProvince(){
      // 没有省份，或者是直辖市的，城市不能选择
      if(this.queryParams.province == null
        || this.provinceIsCitys.includes(this.queryParams.province)){
        this.citySelect = false;
      } else {
        // 获取下一级城市列表
        this.cityList = [];
        let res = await nodeServer.regionInfo_getNextByRegionCodeAndLevel({
          region_code: this.queryParams.province,
          region_level: 2
        });
        if(res.success && res.content){
          this.cityList = res.content;
        }
        this.citySelect = true;
      }
    },
    // 查询
    async searchBtn(){
      let searchParams = {
        pageNum: this.pageData.currentPage,
        pageSize: this.pageData.pageSize,
        province: this.queryParams.province,
        city: this.queryParams.city,
        is_examine: this.queryParams.is_examine,
      }
      let res = await nodeServerParttime.parttimeJobAuditInfo_getPage(searchParams);
      if(res.success && res.content){
        // 处理数据
        for(let row of res.content.rows ){
          if(row.examine_time){
            row.examine_time = dateUtils.getDateString(new Date(row.examine_time));
          }

          // 审核状态
          let examineTypeMap = {0: '审核不通过', 1: '审核通过'};
          row.examine_type_enum = examineTypeMap[row.examine_type];
          // 是否审核
          let isNoMap = {0: '否', 1: '是'};
          row.is_examine_enum = isNoMap[row.is_examine];
          // 是否启用
          let trueFalseMap = {0: false, 1: true};
          row.is_active_enum = trueFalseMap[row.is_active];
          let auditTypeMap = {1: '新建', 2: '更新', 3: '启用'};
          row.audit_type_enum = auditTypeMap[row.audit_type];
          // 审核状态展示 examine_status_enum(0: 审核中, 1: 审核通过, 2: 审核不通过)
          let examineStatusMap = {0: '审核中', 1: '审核通过', 2: '审核不通过'};
          if(row.is_examine == 0){
            row.examine_status = 0;
          }else{
            if(row.examine_type == 0){
              row.examine_status = 2;
            }else{
              row.examine_status = 1;
            }
          }
          row.examine_status_enum = examineStatusMap[row.examine_status];
        }

        this.pageData.rows = res.content.rows;
        this.pageData.total = res.content.total;
      }
    },
    async openAuditInfoDialog(index, row){
      row.keywordList = [];
      if(row.keyword){
        row.keywordList = row.keyword.split(',');
      }
      this.auditInfo = row;
      // 初始化默认审核值
      this.auditInfo.examine_type = 1;
      this.auditInfo.examine_message = '';
      // 打开弹窗
      this.openAuditInfoFlag = true;
    },
    async auditInfoBtn(){
      const myComponentInstance = this.$refs.AuditInfoFormRef;
      if (!myComponentInstance) return
      await myComponentInstance.validate((valid, fields) => {
        if (valid) {
          this.auditParttimeJob();
        } else {
          // console.log('error submit!', fields)
          // ElMessage.error('请按要求填写内容');
        }
      })
    },
    async auditParttimeJob(){
      let params = {
        id: this.auditInfo.id,
        examine_type: this.auditInfo.examine_type,
        examine_message: this.auditInfo.examine_message,
      }
      let res = await nodeServerParttime.parttimeJobAuditInfo_audit(params);
      if(res.success){
        // 插入成功， 关闭弹窗，刷新列表
        ElMessage.success('审核成功');
        // 关闭弹窗
        this.openAuditInfoFlag = false;
        this.searchBtn();
      }
    },
    currentChange(currentPage){
      this.pageData.currentPage = currentPage;
      this.searchBtn()
    },
  }
}
</script>
<style lang="scss" scoped>
.manage-parttime-audit-info{
  width: 100%;
  height: 100%;
  .table-header{
    height: 80px;
    line-height: 80px;
    display: flex;
    .query-item{
      margin-left: 64px;
      .query-item-label{
        text-align: right;
      }
      .query-item-input{
        margin-left: 16px;
        width: 120px;
      }
    }
    .btn-group{
      margin-left: 32px;
      .el-button{
        margin-right: 12px;
        margin-bottom: 6px;
      }
    }
  }
  .table-content{
    height: calc(100% - 80px);
    overflow-y: auto;
    .pagination{
      margin-top: 1rem;
      position: relative;
      float: right;
      display: flex;
      .el-pagination{
        margin-right: 32px;
      }
      .total{
        line-height: 40px;
        font-size: 14px;
        margin-right: 24px;
      }
    }
  }
}

//弹窗
.audit-parttime-job-info{
  padding: 0 20px 24px 20px;
  .info-group{
    .info-group-title{
      height: 24px;
      line-height: 22px;
      font-size: 14px;
      border-bottom: 1px solid #8c8c8c;
      margin-bottom: 16px;
    }
    .info-row{
      display: flex;
      .info-item{
        flex: 1;
        .keyword-input, .keyword-button-new-tag{
          width: 90px !important;
        }
        .el-tag{
          margin-right: 8px;
        }
        .tip-text{
          font-size: 12px;
          margin-left: 12px;
          color: #8c8c8c;
        }
      }
    }
  }
  .btn-group{
    text-align: center;
  }
}
</style>
<style lang="scss">
.audit-info-dialog{
  width: 800px !important;
  .el-dialog__body{
    padding: 0;
  }
}
</style>
