import axios from 'axios'
import {getCookie, setCookie} from '../net/cookies'

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, msg) => {
  switch (status) {
    // 400: 错误请求
    case 400:
      app.$message.error(status + ' ' + '请求错误')
      break
      // 401: 未登录状态，跳转登录页
    case 401:
      app.$message.error(status + ' ' + '请登录')
      // loginOut()
      break
      // 403 token过期
      // 清除token并跳转登录页
    case 403:
      app.$message.error(status + ' ' + '请重新登录')
      // loginOut()
      break
      // 404请求不存在
    case 404:
      if (msg) {
        app.$message.error(msg)
      } else {
        app.$message.error('请求的资源不存在')
      }
      break
    case 500:
    case 502:
    case 503:
      if (msg) {
        app.$message.error(msg)
      } else {
        app.$message.error('系统繁忙，请稍后重试')
      }
      break
    default:
  }
}

var instance = axios.create({
  timeout: 2000 * 10,
  withCredentials: false
})

instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset="ISO-8859-1"'
instance.defaults.headers.post.Accept = 'application/json; charset=utf-8'
// 自动带上登录信息
let token = getCookie("token");
if(token){
  instance.defaults.headers.post['Authorization'] = token;
  instance.defaults.headers.get['Authorization'] = token;
}

/**
 * 请求拦截器
 */
instance.interceptors.request.use(
  config => {
    // 登录流程控制中，根据本地是否存在token判断用户的登录情况
    // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
    // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
    // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
    return config
  },
  error => Promise.error(error))

// 响应拦截器
instance.interceptors.response.use(
  res => {
    if (res.status === 200) {
      // 登录过期
      /*if (res.data && res.data.code == 2007) {
        app.$message.error(res.data.message + ', ' + '请登录')
        loginOut()
      }*/
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res)
    }
  },
  error => {
    const {
      response
    } = error
    if (response) {
      // 请求已发出，但是不在2xx的范围
      errorHandle(response.status, response.data.message)
      return Promise.reject(response)
    } else {
      // 处理断网的情况
      // eg:请求超时或断网时，更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
      if (!window.navigator.onLine) {
        //  store.commit('changeNetwork', false);
        return Promise.reject(error)
      } else {
        return Promise.reject(error)
      }
    }
  })

export default instance
