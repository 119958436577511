import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Main from '../views/Main.vue'
import Course from '../views/Course.vue'
import WeixinLogin from '../views/WeixinLogin.vue'
import WeixinLoginSuccess from '../views/WeixinLoginSuccess.vue'
import WeixinLoginOffice from '../views/WeixinLoginOffice.vue'
import ManageModule from '../views/ManageModule.vue'
import ParttimeJob from '../views/ParttimeJob.vue'
import { OUTPUT_DIR } from '../config/index';


let routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/',
    children: [
      {
        path: '/',
        name: 'Main',
        component: Main,
      },
      {
        path: '/course',
        name: 'Course',
        component: Course,
      },
      {
        path: '/manage_module',
        name: 'ManageModule',
        component: ManageModule,
      },
      {
        path: '/parttime_job',
        name: 'ParttimeJob',
        component: ParttimeJob,
      }
    ]
  },
  {
    path: '/weixin_login',
    name: 'WeixinLogin',
    component: WeixinLogin,
  },
  {
    path: '/weixin_login_success',
    name: 'WeixinLoginSuccess',
    component: WeixinLoginSuccess,
  },
  {
    path: '/weixin_login_office',
    name: 'WeixinLoginOffice',
    component: WeixinLoginOffice,
  }
]



const router = createRouter({
  history: createWebHistory(OUTPUT_DIR),
  routes
})

export default router

